export const environment = {
  isProduction: true,
  // apiUrl: 'http://localhost:3000/v1',
  apiUrl: 'https://api-staging.grikocnavigasyon.com/v1',
  googleSignInClientId: '385909989234-370g4fth2v04g6qtlfioe3geshhbpd55.apps.googleusercontent.com',
  oneSignalAppId: 'c6589d2a-7401-42fc-b3d3-e35691e4dbb9',
  baseUrl: 'http://localhost:4200',
  wsUrl: 'https://api-staging.grikocnavigasyon.com',
  logging: false,
  flowPlayerToken: 'eyJraWQiOiJCWE9lQWtoZXdrUGkiLCJ0eXAiOiJKV1QiLCJhbGciOiJFUzI1NiJ9.eyJjIjoie1wiYWNsXCI6MzgsXCJpZFwiOlwiQlhPZUFraGV3a1BpXCJ9IiwiaXNzIjoiRmxvd3BsYXllciJ9.1w5uhdc4lrmTqeLbCP_HEJZbZw9eX5Gxqnk-B_9E29fb9hZ8FHntXblRcR7VU8iYjMJjwuXEo5E05pX-NA9TTg',
  videoProxyToken: 'yJg5Gdm7xsUaHZ4j8BMbPrXWYncDpFh9',
  cdnBase: 'https://cdn.grikocnavigasyon.com',
  liveChatIsActive: false,
  examMentorIsActive: true,
  workbooksIsActive: true,
  cookiebotCbid: 'c6b5017c-8f60-45cb-83d6-1360d8daec8e'
};
