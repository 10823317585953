import { Component, Input } from '@angular/core';
import { IBlogArticle } from "../../../../core/models/blog/blog-article.model";
import { Router } from "@angular/router";

@Component({
  selector: 'app-blog-list-item',
  templateUrl: './blog-list-item.component.html',
  styleUrl: './blog-list-item.component.scss'
})
export class BlogListItemComponent {
  @Input() article?: IBlogArticle;
  @Input() height = 'auto';
  @Input() size: 'large' | 'small' = 'small';

  constructor(
    public router: Router
  ) {
  }
}
