import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

@Injectable()
export class PageRouteService {
  constructor(
    private router: Router
  ) {
  }

  toNotFound() {
    this.router.navigateByUrl('/dashboard');
  }
}
