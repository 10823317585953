import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { catchError, Observable, throwError } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable()
export class ApiService {
  constructor(
    private http: HttpClient,
  ) {
  }

  get(path: string, queryString = ''): Observable<any> {
    const reqUrl = `${environment.apiUrl}${path}${queryString}`;

    const time = (new Date()).getTime();
    let timeSuffix = `?tm=${time}`;
    if (reqUrl.includes('?')) {
      timeSuffix = `&tm=${time}`;
    }

    return this.http.get(`${reqUrl}${timeSuffix}`)
      .pipe(catchError(this.formatErrors));
  }

  put(path: string, body: object = {}): Observable<any> {
    return this.http.put(
      `${environment.apiUrl}${path}`,
      body
    ).pipe(catchError(this.formatErrors));
  }

  putToUrl(url: string, data: any): Observable<any> {
    return this.http.put(`${url}`, data);
  }

  getFromUrl(url: string):Observable<any> {
    return this.http.get(`${url}`);
  }

  patch(path: string, body: object): Observable<any> {
    return this.http.patch(
      `${environment.apiUrl}${path}`,
      body
    ).pipe(catchError(this.formatErrors));
  }

  post(path: string, body: any): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}${path}`,
      body
    ).pipe(catchError(this.formatErrors));
  }

  delete(path: string): Observable<any> {
    return this.http.delete(
      `${environment.apiUrl}${path}`
    ).pipe(catchError(this.formatErrors));
  }


  private formatErrors(error: any): Observable<any> {
    return throwError(error.error);
  }

}
