<div class="blog-list-item clickable">
  <a [routerLink]="router.url.includes('/dashboard') ? ('/dashboard/blog/' + article.slug) : '/blog/' + article.slug" >
    <div class="image-container">
      <img [alt]="article.title" [style.height]="height" [src]="article?.thumbnails?.medium">
      <div class="after">
        <div class="blog-info p-3">
          <div class="d-flex align-items-center">
            <div
              class="fw-400 text-white category-badge px-2 py-1"
              [class.fs-xsmall]="size == 'small'"
              [class.fs-normal]="size == 'large'"
              *ngIf="article?.blogCategories.length > 0">
              {{article.blogCategories[0].name}}
            </div>
            <p
              class="fw-400 ms-2 color-gray-200"
              [class.fs-large]="size == 'large'"
              [class.fs-small]="size == 'small'"
            >{{article?.publishedAt|date: 'MMM d, y'}}</p>
          </div>
          <h3
            class="mt-2 max-line-3 color-gray-50 fw-700"
            [class.fs-title-small]="size == 'large'"
            [class.fs-large]="size == 'small'"
          >{{article.title}}</h3>
        </div>
      </div>
    </div>
  </a>
</div>
