import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BlogListItemComponent } from './blog-list-item/blog-list-item.component';
import { SharedModule } from "../../shared.module";
import { RouterModule } from "@angular/router";


@NgModule({
  declarations: [
    BlogListItemComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
  ],
  exports: [
    BlogListItemComponent,
  ]
})
export class SharedBlogModule {
}
